//border styles

$primaryBorderWidth: 1px;
$primaryBorderStyle: solid;
$primaryBorderColor: #e5e7eb;

$primaryBorderRadius: 10px;

$primary-txt-color: #4b5563;
$secondary-txt-color: #0088cf;

//buttons
$btn-primary-bg-color: #0088cf;
$btn-primary-txt-color: #fff;

$btn-secondary-bg-color: #fff;
$btn-secondary-txt-color: #0088cf;
$btn-secondary-border-color: #0088cf;

$btn-tertiary-bg-color: #c81e1e;
$btn-tertiary-txt-color: #fff;

//inputs
$input-primary-bg-color: #f9fafb;
$input-primary-border-color: #d1d5db;
$input-primary-placeholder-color: #a4a4a4;
$input-primary-txt-color: #6b7280;

$required-mark-color: #4b5563;

$error-msg-color: #d32f2f;

//steps
$step-incompleted: #d1d5db;
$step-completed: #0088cf;
