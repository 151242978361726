@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
	font-family: 'Inter', sans-serif !important;
}

.header {
	font-family: 'Inter', sans-serif !important;
	text-align: center;
	&-primary {
		font-size: 24px;
		font-weight: 700 !important;
		line-height: 36px;
		letter-spacing: -0.01em;
		color: $secondary-txt-color;
	}
	&-secondary {
		font-size: 24px !important;
		font-weight: 700 !important;
		line-height: 30px !important;
		letter-spacing: 0em !important;
	}
	&-form {
		font-size: 18px !important;
		font-weight: 700 !important;
		letter-spacing: 0em !important;
	}
}

.primary-txt-style {
	font-size: 14px;
	line-height: 17.5px;
	font-weight: 400;
	color: $primary-txt-color;
}
