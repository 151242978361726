.button {
	border-radius: 8px !important;
	padding: 10px 20px 10px 20px;
	text-transform: none !important;
	font-weight: 500 !important;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.3s !important;
	&:hover {
		opacity: 0.8;
	}
	&--primary {
		background-color: $btn-primary-bg-color !important;
		color: $btn-primary-txt-color !important;
		text-decoration: none !important;
		&--wide {
			width: 330px !important;
		}
		&--dialog-size {
			width: 230px !important;
		}
		&--normal {
			width: 135px !important;
		}
	}
	&--secondary {
		background-color: $btn-secondary-bg-color !important;
		color: $btn-secondary-txt-color !important;
		border: 1px solid $btn-secondary-border-color !important;
		text-decoration: none;
		&--normal {
			width: 135px;
		}
	}
	&--tertiary {
		background-color: $btn-tertiary-bg-color !important;
		color: $btn-tertiary-txt-color !important;
		text-decoration: none !important;
		&--wide {
			width: 330px !important;
		}
		&--dialog-size {
			width: 230px !important;
		}
		&--normal {
			width: 135px !important;
		}
	}
}
.form-container {
	width: 100%;
	max-width: 380px;
	.inputs_containers {
		position: relative;

		border: 0px !important;
		.validaton-mark {
			position: absolute;
			left: -15px;
			color: $required-mark-color;
		}
		.edit-icon {
			position: absolute;
			width: 20px;
			right: 10px;
			z-index: 2;
			top: 10px;
		}
	}
	.form-element {
		&--margin-bottom {
			margin-bottom: 24px;
		}

		&__input input {
			color: $input-primary-txt-color;
			border-radius: 8px;
			background-color: $input-primary-bg-color !important;
			text-align: center;
			input,
			textarea {
				text-align: center;
				border-radius: 8px;

				&::placeholder {
					text-align: center;
					color: $input-primary-placeholder-color;
				}
				&::-webkit-input-placeholder {
					/* Edge */
					text-align: center !important;
					color: $input-primary-placeholder-color;
				}

				&:-ms-input-placeholder {
					/* Internet Explorer 10-11 */
					text-align: center;
					color: $input-primary-placeholder-color;
				}
			}
		}
		&__textarea {
			color: $input-primary-txt-color;
			border-radius: 8px;
			background-color: $input-primary-bg-color !important;
		}
		&__file-name {
			text-align: left;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			color: $secondary-txt-color;
			font-weight: 700;
			font-size: 14px;
			margin-bottom: 10px;
			&__icon {
				margin-right: 5px;
			}
		}
		&__file-hint {
			font-size: 11px;
			font-weight: 400;
			line-height: 14px;
			letter-spacing: 0em;
			text-align: left;
			color: #9ca3af;
			margin-bottom: 10px;
		}
	}

	.margin {
		&--margin-bottom {
			margin-bottom: 24px;
		}
	}
	.custom-checkbox {
		text-align: left;
		align-items: flex-start;
		.MuiCheckbox-root {
			padding-top: 0 !important;
			color: $input-primary-border-color !important;
			&:hover,
			&:active,
			&:focus {
				background: none;
			}
		}
	}
	.MuiOutlinedInput-root {
		border-radius: 10px;
		border: none !important;
	}
}
.MuiFormHelperText-contained,
.error-msg {
	font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
	font-weight: 400;
	font-size: 0.75rem;
	line-height: 1.66;
	letter-spacing: 0.03333em;
	text-align: left;
	margin-top: 4px;
	margin-right: 14px;
	margin-bottom: 0;
	margin-left: 0px !important;
	color: $error-msg-color;
}
.validation-icon {
	position: absolute;
	left: -30px;
	top: 15px;
	width: 15px;
	height: 15px;
}
.show-more {
	padding-left: 31px;
	cursor: pointer;
	margin-bottom: 24px !important;
}
