.email {
	margin: 0;
	text-decoration: underline;
	color: $secondary-txt-color;
	&--recruitment {
		&:after {
			content: 'rekrutacja@medisept.pl';
		}
	}
	&--support {
		&:after {
			content: 'support@tcreate.pl';
		}
	}
}

.required-info {
	font-weight: 400;
	font-size: 11px;
	line-height: 14px;
	padding-top: 5px;
	&--support {
		display: flex;
		justify-content: center;
		gap: 3px;
	}
}
