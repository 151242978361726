html {
	min-height: 100%;
}

body {
	min-height: 100vh;
	background-image: url('../assets/backgrounds/bg.jpg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: fixed;
}

#root {
	display: flex;
	justify-content: center;

	position: relative;
	.pages-container {
		width: 100%;
		max-width: 700px;
		.logo-container {
			position: absolute;
			margin-left: auto;
			margin-right: auto;
			left: 0;
			right: 0;
			width: 255px;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: #fff;
			top: 55px;
		}
		.bordered-container {
			background-color: #fff;
			width: 100%;
			padding: 0px 0px 30px 0px;
			text-align: center;
			border-width: $primaryBorderWidth;
			border-style: $primaryBorderStyle;
			border-color: $primaryBorderColor;
			border-radius: $primaryBorderRadius;
			.header-img {
				border-radius: $primaryBorderRadius $primaryBorderRadius 0px 0px;
				width: 100%;
				height: 145px;
				background-image: url('../assets/backgrounds/header-bg.jpg');
				background-position: center;
				background-size: cover;
				background-repeat: no-repeat;
				margin-bottom: 20px;
			}
			.step {
				display: flex;
				justify-content: center;
			}
		}
	}
}
