.register {
	&-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		@media (max-width: 500px) {
			flex-direction: column !important;
		}
		&__logo {
			width: 100px;
			@media (max-width: 500px) {
				margin-bottom: 20px;
			}
		}
		&__steps {
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-size: 12px !important;
			font-weight: 700;
			font-size: 14px;
			line-height: 14px;
			width: 300px;
			&__step {
				.step-icon {
					margin-right: 5px;
				}
				display: flex;
				align-items: center;
				&--incompleted {
					color: $step-incompleted;
				}
				&--completed {
					color: $step-completed;
					fill: $step-completed !important;
				}
			}
			&__separator {
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
	.preview-container {
		width: 100%;
		max-width: 380px;
		.inputs_containers {
			position: relative;
			.validaton-mark {
				position: absolute;
				left: -15px;
				color: $required-mark-color;
			}
		}
		.preview-element {
			&__field {
				margin-bottom: 20px;
				color: $input-primary-txt-color;
				border-radius: 8px;
				background-color: $input-primary-bg-color;
				height: 1.4375em;
				padding: 8.5px 14px;
				&__textarea {
					margin-top: 10px;
					text-align: left !important;
					height: unset;
				}
			}
			&__file-name {
				text-align: left;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				color: $secondary-txt-color;
				font-weight: 700;
				font-size: 14px;
				margin-bottom: 10px;
				&__icon {
					margin-right: 5px;
				}
			}
		}
	}
}
