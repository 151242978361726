.home {
	&-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		@media (max-width: 500px) {
			flex-direction: column !important;
		}
		&__logo {
			width: 100px;
			@media (max-width: 500px) {
				margin-bottom: 20px;
			}
		}
	}
}
